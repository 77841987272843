.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: var(--spacing-small);
  padding-inline-end: var(--spacing-small);
  box-sizing: border-box;
}

.title {
  font-size: var(--font-size-jumbo);
  margin-block-end: var(--spacing-medium);
  text-align: center;
  line-height: normal;
}

.goBack {
  display: flex;
  justify-content: center;
  margin-block-start: var(--spacing-medium);
}

.reportBug {
  margin-block-start: var(--spacing-mega);
}
